import { RouteComponentProps, withRouter } from 'react-router-dom';
import redux, { IState, ReduxMap } from '../../redux/redux';
import FlexFlowManager from '../../Components/FlexFlow/Setup/FlexFlowManager';
import FlexFlow from '../../Components/FlexFlow/FlexFlow';
import FlexFlowDone from '../../Components/FlexFlow/FlexFlowDone';
import { useEffect, useState } from 'react';
import StoreX, { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import XImage from '../../utils/xImage';
import Button from '../../Components/_Core/Button';
import Icon, { IconType } from '../../Components/Icon/Icon';
import BranchContainer from '../../Components/Setup/Branches/BranchContainer';
interface IProps extends RouteComponentProps<IParams>, IState {}
interface IParams {
  type: string;
}

interface IClientSiteInfo {
  ClientId: number;
  ClientPublicId: string;
  FairId: number;
  FairPublicId: string;
  FairName: string;
  ClientName: string;
  SiteUrl: string;
  FairDescription: string;
  Logo: string;
}

const ExternalLandingPage = (props: IProps) => {
  //console.log('Page...',props.match.params);
  const [email, setEmail] = useState<string | null>(StoreX.GetQueryStringValue('email'));
  const [showDev, setShowDev] = useState<boolean>(StoreX.GetQueryStringValue('dev') ? true : false);
  const [externalOrgId, setExternalOrgId] = useState<string | null>(StoreX.GetQueryStringValue('ex-org-id'));
  const [externalSubId, setExternalSubId] = useState<string | null>(StoreX.GetQueryStringValue('ex-sub-id'));
  const [returnTo, setReturnTo] = useState<string | null>(StoreX.GetQueryStringValue('return-to'));
  const [siteLoaded, setRSiteLoaded] = useState<boolean>(StoreX.GetQueryStringValue('site-loaded') ? true : false);
  const [sites, setSites] = useState<IClientSiteInfo[]>([]);
  const [showSites, setShowSites] = useState<boolean>(false);
  const [showFlow, setShowFlow] = useState<boolean>(false);

  useEffect(() => {
    if (!siteLoaded) {
      findSites();
    }
  }, []);

  useEffect(() => {
    if (siteLoaded && props.User && props.User.signedIn) {
      setShowFlow(true);
      redux.showLogin(false);
    } else if (siteLoaded) {
      redux.showLogin(true);
    }
  }, [props.User]);

  const findSites = () => {
    if (email) {
      store.server.postApi<ServerResponse<IClientSiteInfo[]>>(`../ProjectBoard/FindMyFair`, { email: email }).then((x) => {
        if (x.Success) {
          setSites(x.Value ?? []);
          setShowSites(true);
        } else {
          toast.error(x.Message);
        }
      });
    }
  };

  const siteSelected = (site: IClientSiteInfo) => {
    //redirect to this new site...
    let path = `${window.location.pathname}${window.location.search}`;
    if (path.indexOf('/') === 0) path = path.substring(1);
    let url = `${site.SiteUrl}/${path}`;
    url += url.indexOf('?') > -1 ? '&site-loaded=1' : '?site-loaded=1';
    url += `&f=${site.FairPublicId}`;

    if (url.indexOf('localhost:') > 0) url = url.replace('https:', 'http:');

    window.location.href = url;
  };

  return (
    <>
      <div className="page-header">ProjectBoard Integration</div>
      {sites && showSites && (
        <div className="site-list">
          {sites.length > 0 ? (
            <>
              <h1>Connect your zFairs site with ProjectBoard</h1>
              The first step to connect your zFairs site to ProjectBoard is to select your zFairs site.
              {sites.map((x, i) => {
                return (
                  <div key={i} className="site">
                    <div className="site-logo">
                      <XImage src={StoreX.BuildUrl(x.Logo)} />
                    </div>
                    <div>
                      <h2>{x.FairName}</h2>
                      {x.FairDescription && <div dangerouslySetInnerHTML={{ __html: x.FairDescription }} />}
                    </div>
                    <Button
                      type="new"
                      text="Select"
                      onClick={() => {
                        siteSelected(x);
                      }}
                      addClass="site-select-btn"
                    />
                  </div>
                );
              })}
            </>
          ) : (
            <div className="flex-center">
              <div>
                <h2>Oops!</h2>
                <p>
                  <strong>We couldn't find a zFairs account to connect with ProjectBoard</strong>
                </p>
                <p>If you already have a zFairs account, please ensure your account email address matches the one you use on ProjectBoard and try again.</p>
                <p>
                  If you don’t have a zFairs account yet, <a href="https://zfairs.com/event/host">click here</a> to create one!
                </p>
              </div>
            </div>
          )}
        </div>
      )}

      {props.User?.signedIn && showFlow && (
        <>
          <h1>Connect to ProjectBoard+</h1>
          <BranchContainer branchId={'projectboard-learn-more'} extraProps={`org:${externalOrgId}|event:${externalSubId}|to:${returnTo}`} />
        </>
      )}

      {showDev && (
        <>
          <hr />
          <h1>External Landing Page</h1>
          <h2>API Info</h2>
          The execution of this API is to send user to this landing page...
          <div>
            <strong>Parameters</strong>
          </div>
          <ul>
            <li>
              <strong>type</strong>(path paramter) - type of third party id: projectboard
            </li>
            <li>
              <strong>email</strong>(query string paramter) - Email address that may be a zFairs account / belong to a zfairs client
            </li>
            <li>
              <strong>ex-org-id</strong>(query string paramter) - External Organization Id: 3rd parties id to which users may connect with zFairs{' '}
            </li>
            <li>
              <strong>ex-sub-id</strong>(query string paramter - optional) - External Sub-Organization Id: 3rd parties id to which users may connect with zFairs{' '}
            </li>
            <li>
              <strong>return-to</strong>(query string paramter - optional) - URL of where you would like the user to return once complete
            </li>
          </ul>
          <div>
            <strong>Sample: </strong>

            <ul>
              <li>https://sample.zfairs.com/app/external/landing/projectboard?ex-org-id=1234&ex-sub-id=897&email=jane@example.com&return-to=https://projectboard.world/</li>
            </ul>

            <div>
              <strong>Current Values: </strong>
            </div>
            <ul>
              <li>{window.location.href}</li>
              <li>
                <strong>type</strong> : {props.match.params.type ?? <>n/a</>}{' '}
              </li>
              <li>
                <strong>email</strong> : {email ?? <>n/a</>}{' '}
              </li>
              <li>
                <strong>ex-org-id</strong> : {externalOrgId ?? <>n/a</>}{' '}
              </li>
              <li>
                <strong>ex-sub-id</strong> : {externalSubId ?? <>n/a</>}{' '}
              </li>
              <li>
                <strong>return-to</strong> : {returnTo ?? <>n/a</>}{' '}
              </li>
            </ul>
          </div>
          <h2>ProjectBoard+</h2>
          <h4>Use Cases</h4>
          <ul>
            <li>As a user trying to connect my zFairs site with Projectboard+ when I press the link "Connect with zFairs" then I am brought to this external landing page.</li>
            <li>As a user on the zFairs External Landing Page I am shown a list of my zFairs sites.</li>
            <li>As a user when I select my zFairs site then I am required to authenticate / login. </li>
            <li>As a user when I have selected my zFairs site and authenticated then I can review the ProjectBoard+ connection flow.</li>
            <li>
              As a user once I have completed the ProjectBoard+ connection flow and when I select to proceed and connect my zFairs site with ProjectBoard+ then my zFairs site is connected to the
              organization and event that was passed to zFairs. (pull org, pull event, pull user, create thirdPartyLink, setup sso, push projects if applicable, etc)
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default withRouter(ReduxMap(ExternalLandingPage));
