import React, { useEffect, useState } from 'react';
import './App.css';
import './Components/Tools/Tools.css';
import './theme.css';
import './AppStyle.css';

import Loading from './Components/LoadingScreen/loading';
import routes from './routes';
import StoreX, { store } from './redux/oldStore';
import { toast, ToastContainer } from 'react-toastify';
import { EasyImage } from './Components/Tools/ExpandableDiv';

import 'react-toastify/dist/ReactToastify.css';
// import './ReactToastify.css'
import Modal from './Components/Modal/Modal';
import UserLogin from './Components/UserLogin/UserLogin';
import EventSessionCheckIn from './Components/SetupTools/EventSessions/EventSessionCheckIn';
import BranchSearch from './Components/Setup/Branches/BranchSearch';
import redux, { IState, ReduxMap } from './redux/redux';
import Icon, { IconType } from './Components/Icon/Icon';
import Header from './Components/PageParts/Header';
import Widget from './Components/Widgets/Widget';

const App = (props: IState) => {
  const [hideLayoutStuff, setHideLayoutStuff] = useState(false);
  const [showHud, setShowHud] = useState(true);

  useEffect(() => {
    redux.Reload();
    let hideLayout = StoreX.GetQueryStringValue('hlo');
    setHideLayoutStuff(hideLayout ? true : false);

    return () => {};
  }, []);

  return (
    <>
      {props.Settings && !props.Settings.UseOldStyling ? (
        <>
          <div className="x-style">
            <div className="header-bar">
              <div className="x-container">
                <Header useOld={false} state={props} />
              </div>
            </div>

            <div className="x-color-bar">
              <div className="x-container x-body">
              <div className="main-body-wrapper">
                {/* Left Sidebar Widget */}
                {props.WidgetBarLeft && props.WidgetBarLeft.length > 0 && <div  className="side-bar-widgets left-side">
                  {props.WidgetBarLeft.map((x,i)=>{
                    return <Widget state={props} type={x} key={i}/>
                  })}
                </div>}

                {/* Routes */}
                <div className='route-wrapper'>{routes}</div>
                
                {/* Right Sidebar Widget */}
                {props.WidgetBarRight && props.WidgetBarRight.length > 0 && <div  className="side-bar-widgets right-side">
                  {props.WidgetBarRight.map((x,i)=>{
                    return <Widget state={props} type={x} key={i}/>
                  })}
                </div>}
              </div>
              </div>
            </div>

            <div className="x-footer">
              <div className="x-container">
                <div className="flex-between">
                  <div>
                    <h5>Brought to you by</h5>
                    <a href="https://zfairs.com">
                      <EasyImage src="https://zfairs.com/file/zFairsLight.svg" alt="zFairs logo" className="app-logo" />
                    </a>
                  </div>
                  {props.Settings && props.Settings.isHistoryContest && (
                    <div>
                      <h5>Provided by</h5>
                      <a href="https://nhd.org">
                        <EasyImage src={StoreX.BuildUrl('../api/FileApi/FileFetcherClient/e99bb006-500d-40fb-82df-6946fe3e383a')} alt="nhd logo" />
                      </a>
                    </div>
                  )}
                  <div>
                    <div>
                      <strong>&copy; illuminating Software LLC {new Date().getFullYear()}</strong>
                      <br />
                      zFairs {props.SettingsResponse && props.SettingsResponse.Version && <span>{props.SettingsResponse.Version}</span>}
                    </div>
                    <div>
                      <a href="https://zfairs.com/Policy/Privacy">Privacy Policy</a> | <a href="https://zfairs.com/Policy/TermsOfUse">Terms of Use</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {props.Settings && <BranchSearch state={props} />}

            {props.ShowLogin && (
              <Modal
                title={props.Settings?.text.NavLogin ?? 'Sign-in'}
                setModalOpen={() => {
                  redux.showLogin(false);
                }}>
                <UserLogin></UserLogin>
              </Modal>
            )}
          </div>
        </>
      ) : !hideLayoutStuff ? (
        <>
          <div className="backdrop"></div>
          <div className="container">
            <Header state={props} useOld={true} />
            <div className="head-room"></div>
            <div className="app-main-body">{routes}</div>
            <div className="head-room-xl"></div>
            <div className="footer primary-bg no-print">
              <div>
                <EasyImage src="https://zfairs.com/file/zFairsLight.svg" alt="zFairs logo" className="app-logo" />
              </div>
              <div>{props.SettingsResponse && <>{props.SettingsResponse.Version}</>}</div>
            </div>
          </div>

          {props.Settings && <BranchSearch state={props} />}

          {props.ShowLogin && (
            <Modal
              title={props.Settings?.text.NavLogin ?? 'Sign-in'}
              setModalOpen={() => {
                redux.showLogin(false);
              }}>
              <UserLogin inModal={true}></UserLogin>
            </Modal>
          )}
        </>
      ) : (
        <>{routes}</>
      )}
      {props.IsLoading && <Loading></Loading>}
      {props.StyleText && <style>{props.StyleText}</style>}
      {props.SettingsResponse && props.SettingsResponse.IsTest && <div className="is-test">TEST SITE</div>}

      <ToastContainer theme="dark" autoClose={8000} />
      {props.SettingsResponse?.Settings.eventSessions && <EventSessionCheckIn />}
      {props.HudInfo && props.HudInfo.JudgingAssignments && props.HudInfo.JudgingAssignments.length > 0 && (
        <>
          {showHud && (
            <Modal setModalOpen={setShowHud} title={'HUD'}>
              <div>
                <h3>Interview Times</h3>
                Please arrive 20 minutes before your scheduled interview, incase your judges arrive early.
                <div>
                  Scheduled Times:
                  <ul>
                    {props.HudInfo.JudgingAssignments.map((x, i) => {
                      return (
                        <li className="JudingTime" key={`hud-jat-${i}`}>
                          {x.At}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default ReduxMap(App);
