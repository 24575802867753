import { useEffect, useState } from 'react';
import { Dropdown } from 'reactstrap';
import { GetPeoplePerson } from '../../utils/CommonTypes/PeoplePageTypes';

import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import { toast } from 'react-toastify';
import Button from '../_Core/Button';
import './BulkEditsStyles.css';
import { IListItem } from '../FloorPlanner/FloorPlannerTypes';
import { store } from '../../redux/oldStore';
import { ServerResponse } from '../../utils/Server';

interface IProps {
  selected: Record<string, boolean>;
  refresh: () => void;

  people: GetPeoplePerson[];
  schools: IListItem[];
}

interface ISelectedPeopleInfo {
  allSelectedAreParticipants: boolean;
  allSelectedAreNonParticipants: boolean;
  numberOfSelected: number;
  selectedIds: string[];
}

interface IForm {
  schoolId?: number;
  teacherId?: number;
}

const BulkEdits = (props: IProps) => {
  const [selectedStats, setSelectedStats] = useState<ISelectedPeopleInfo>();
  const [showMergeTeacher, setShowMergeTeacher] = useState<boolean>(false);
  const [peopleDic, setPeopleDic] = useState<Record<string, GetPeoplePerson>>({});
  const [selectedPeople, setSelectedPeople] = useState<GetPeoplePerson[]>([]);
  const [showBulkEditStudent, setShowBulkEditStudent] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<IListItem[]>([]);
  const [form, setForm] = useState<IForm>({});

  useEffect(() => {
    if (props.people) {
      let dic = {};

      props.people.forEach((x) => {
        dic[x.Id] = x;
      });

      setPeopleDic(dic);
    }
  }, [props.people]);

  useEffect(() => {
    let newTeachers = props.people
      .filter((x) => x.Adult && !x.TempPerson && x.MaxRole !== 'Judge')
      .map((x) => {
        return { Id: x.PersonId, Name: `${x.LastName}, ${x.FirstName} ${x.Email}` };
      });

    setTeachers(newTeachers);
  }, [props.people]);

  useEffect(() => {
    let info: ISelectedPeopleInfo = {
      allSelectedAreParticipants: true,
      allSelectedAreNonParticipants: true,
      numberOfSelected: 0,
      selectedIds: Object.keys(props.selected),
    };

    let selectedPeopleArray = props.people.filter((x) => props.selected[x.Id]);

    info.numberOfSelected = selectedPeopleArray.length;
    info.allSelectedAreNonParticipants = selectedPeopleArray.find((x) => x.Adult === false) ? false : true;
    info.allSelectedAreParticipants = selectedPeopleArray.find((x) => x.Adult === true) ? false : true;
    setSelectedPeople(selectedPeopleArray);
    setSelectedStats(info);
  }, [props.selected]);

  const mergeTeachers = () => {

    toast.clearWaitingQueue();
    //tell server of change
    let request = {
      idA:selectedPeople[0].PersonId, //Account that will be removed. should/could be placeholder account...
      idB:selectedPeople[1].PersonId, //Account to keep
    };
    store.server.postApi<ServerResponse<boolean>>(`../Person/MergeTeacher`,request)
    .then(x=>{
      if(x.Success) 
        {//code to process once we tell the server...        
          //tell partent of change
          props.refresh();
          //clear saved data...
          setForm({});
          //done
          toast.dark('Teacher Merged...');
      }
      else {
        toast.error(x.Message);
      }
    });


  };

  const changeTeacher = () =>{

    toast.clearWaitingQueue();
    //tell server of change
    let request = {
      newId:form.teacherId,
      participants: selectedPeople.map(x=>{
        return {personId:x.PersonId, projectId: x.ProjectIdInt}
      })
    };
    store.server.postApi<ServerResponse<boolean>>(`../Person/ChangeTeacher`,request)
    .then(x=>{
      if(x.Success) 
        {//code to process once we tell the server...        
          //tell partent of change
          props.refresh();
          //clear saved data...
          setForm({});
          //done
          toast.dark('Changed teacher...');
      }
      else {
        toast.error(x.Message);
      }
    });

  };

  const changeSchool = ()=>{
    toast.clearWaitingQueue();
    //tell server of change
    let request = {
      newId:form.schoolId,
      participants: selectedPeople.map(x=>{
        return {personId:x.PersonId, projectId: x.ProjectIdInt}
      })
    };
    store.server.postApi<ServerResponse<boolean>>(`../Person/ChangeSchool`,request)
    .then(x=>{
      if(x.Success) 
        {//code to process once we tell the server...        
          //tell partent of change
          props.refresh();
          //clear saved data...
          setForm({});
          //done
          toast.dark('Changed School...');
      }
      else {
        toast.error(x.Message);
      }
    
  });
}

  return (
    <>
      {(selectedStats?.numberOfSelected ?? 0) > 1 && selectedStats?.allSelectedAreParticipants && (
        <button
          type="button"
          className="btn btn-default btn-xs"
          onClick={() => {
            setShowBulkEditStudent(true);
          }}
          title="Bulk Student Edit">
          <Icon type={IconType.magic} />
          Bulk Student Edit
        </button>
      )}

      {showBulkEditStudent && (
        <Modal setModalOpen={setShowBulkEditStudent} title={'Bulk Edit Student Accounts'} size="l">
          <div className="form-horizontal bulk-edit-teacher-school-container">
            <h3>Selected Participants</h3>
            <div className="flex-between">
              {selectedPeople.map((x, i) => {
                return (
                  <div className="bulk-edit-container-person">
                    <div key={i}>
                      {x.FirstName} {x.LastName}
                      <br />
                      {x.ProjectId}
                      <br />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="form-horizontal">
            <div className="bulk-edit-option-card">
              <h3>Change School</h3>
              <hr />
              <div className="form-group">
                <label className="control-label col-sm-4">School</label>
                <div className="col-sm-7">
                  <select
                    value={form.schoolId ?? ''}
                    onChange={(x) => {
                      setForm({ ...form, schoolId: +x.target.value });
                    }}
                    className="form-control">
                    <option value={''}></option>
                    {props.schools.map((x, i) => {
                      return (
                        <option key={i} value={x.Id}>
                          {x.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-offset-4 col-sm-7">
                  <button type="button" className="btn btn-default" title="change School" onClick={changeSchool} disabled={(form.schoolId ?? 0) > 0 ? false:true}>
                    <Icon type={IconType.save} />
                    Change
                  </button>
                </div>
              </div>
            </div>
            <div className="bulk-edit-option-card">
              <h3>Change Teacher</h3>
              <hr />
              <div className="form-group">
                <label className="control-label col-sm-4">Teacher</label>
                <div className="col-sm-7">
                  <select
                    value={form.teacherId ?? ''}
                    onChange={(x) => {
                      setForm({ ...form, teacherId: +x.target.value });
                    }}
                    className="form-control">
                    <option value={''}></option>
                    {teachers.map((x, i) => {
                      return (
                        <option key={i} value={x.Id}>
                          {x.Name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group">
                <div className="col-sm-offset-4 col-sm-7">
                  <button 
                    type="button" 
                    className="btn btn-default" 
                    title="change Teacher" 
                    disabled={(form.teacherId ?? 0) > 0 ? false:true}
                    onClick={changeTeacher}
                  >
                    <Icon type={IconType.save} />
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {selectedStats?.numberOfSelected === 2 && selectedStats.allSelectedAreNonParticipants && (
        <button
          type="button"
          className="btn btn-default btn-xs"
          onClick={() => {
            setShowMergeTeacher(true);
          }}
          title="Merge Adults">
          <Icon type={IconType.magic} />
          Merge Teachers
        </button>
      )}

      {showMergeTeacher && (
        <Modal setModalOpen={setShowMergeTeacher} title={'Merge Teacher Accounts'} size="l">
          <div className="form-horizontal">
            <div className="bulk-merge-container flex-between">
              {selectedPeople.length === 2 && (
                <>
                  <div className="bulk-merg-container-person">
                    <h3 className="bg-warning bulk-merg-container-header">Account to Merge</h3>
                    {selectedPeople[0].FirstName} {selectedPeople[0].LastName}
                    <br />
                    {selectedPeople[0].Email}
                    {selectedPeople[0].IsPlaceHolder && (
                      <>
                        <br />
                        <span className="label label-warning">placeholder</span>
                      </>
                    )}
                  </div>
                  <div>
                    {' '}
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        let value = [...selectedPeople.reverse()];
                        setSelectedPeople(value);
                      }}>
                      <Icon type={IconType.swap} /> Swap
                    </button>
                  </div>
                  <div className="bulk-merg-container-person">
                    <h3 className="bg-success bulk-merg-container-header">Account to Keep</h3>
                    {selectedPeople[1].FirstName} {selectedPeople[1].LastName}
                    <br />
                    {selectedPeople[1].Email}
                    {selectedPeople[1].IsPlaceHolder && (
                      <>
                        <br />
                        <span className="label label-warning">placeholder</span>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="merge-button">
              <button type="button" onClick={mergeTeachers} className="btn btn-secondary ">
                Merge
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BulkEdits;
