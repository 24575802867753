import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import  {store } from '../../redux/oldStore';
import { FullGetPeopleValue, GetPeoplePerson, IFilterGroup, IFilterTypes, IFitlerDisplay, IPeopleSettings, searchPerson, Tag } from '../../utils/CommonTypes/PeoplePageTypes';
import Icon, { IconType } from '../Icon/Icon';
import Modal from '../Modal/Modal';
import PersonContextMenu from './PersonContextMenu';
import PersonRow from './PersonRow';
import Creatable from 'react-select/creatable';
import { Selectable } from '../../utils/CommonTypes/CommonTypes';
import Constants from '../../utils/Constants';
import _ from 'lodash';
import { ServerResponse } from '../../utils/Server';
import { toast } from 'react-toastify';
import { IEntryType } from '../../ObjectTypes/SetupTypes';
import { AdvancedFilter } from './AdvancedFilter';
import TagModal from './TagModal';
import { IState, ReduxMap } from '../../redux/redux';
import BulkEdits from './BulkEdits';

interface PeoplePageProps extends RouteComponentProps, IState {}

interface PageLinkObj {
  index: number;
  firstLetters: string;
  lastLetters: string;
}


const PeoplePage = (props: PeoplePageProps) => {
  const [fullResponse, setFullResponse] = useState<FullGetPeopleValue>();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [allPeople, setAllPeople] = useState<GetPeoplePerson[]>([]);
  const [filteredPeople, setFilteredPeople] = useState<GetPeoplePerson[]>([]);
  const [filteredPeopleByPage, setFilteredPeopleByPage] = useState<GetPeoplePerson[][]>([]);
  const [filterValue, setFilterValue] = useState('');
  const [selectedFilterOptions, setSelectedFilterOptions] = useState<Selectable[]>([]);
  const [personTagDict, setPersonTagDict] = useState<Record<number, Tag>>({});
  const [projectTagDict, setProjectTagDict] = useState<Record<number, Tag>>({});
  const [autoTagDict, setAutoTagDict] = useState<Record<number, Tag>>({});

  const [chosenPerson, setChosenPerson] = useState<GetPeoplePerson>();
  const [clickedIconModalOpen, setClickedIconModalOpen] = useState(false);
  const [pageList, setPageList] = useState<PageLinkObj[]>([]);
  const [pageActive, setPageActive] = useState(0);
  const [filterOptions, setFilterOptions] = useState<IFitlerDisplay[]>();
  const [settings, setSettings] = useState<IPeopleSettings>();
  const [entryTypes, setEntryTypes] = useState<IEntryType[]>();
  const [filterGroup, setFilterGroup] = useState<IFilterGroup>();
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [showBulkTag, setShowBulkTag] = useState<boolean>(false);

  useEffect(() => {
    refreshAll();
  }, []);

  useEffect(() => {
    //build stats...
  }, [allPeople]);

  const refreshAll = (grabRest?: boolean) => {
    store.server.postApi<ServerResponse<FullGetPeopleValue>>('../Person/GetPeople', { grabRest }).then((x) => {
      if (x.Success) {
        setFullResponse(x.Value);
        setAllPeople(x.Value?.People);
        setSettings(x.Value?.Settings);
        setEntryTypes(x.Value?.EntryTypes);
        console.log('Steps: ', x.Value.Steps);
        if (x.Value.PeopleDataStillParsing) {
          //window.setTimeout(refreshAll, 2000);
        }
      } else {
        toast.error(`Error: ${x.Message}`, { autoClose: false });
      }
    });
  };




  useEffect(() => {
    let filter: IFitlerDisplay[] = [];

    if (fullResponse?.MarkReady) filter.push({ type: IFilterTypes.ready, key: `ready:review`, value: 'ready', name: 'Paperwork Marked Ready', children: [] });
    if (fullResponse?.MarkReadyToJudge) filter.push({ type: IFilterTypes.ready, key: `ready:judging`, value: 'readyJudging', name: 'Marked Ready For Judging', children: [] });

    filter.push({ type: IFilterTypes.userType, key: `userType:TeamLeader`, value: 'TeamLead', name: 'Participant: Team Leader', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Team`, value: 'Team', name: 'Project: Team Project', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Students`, value: 'Participant', name: 'Role: Participant', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Temp`, value: 'Temp', name: 'Role: Temp User / Not Registered', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Adults`, value: 'Adult', name: 'Role: Non-Participant / Adult', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Teachers`, value: 'Teacher', name: 'Role: Teacher', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Judges`, value: 'Judge', name: 'Role: Judge', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Staff`, value: 'Staff', name: 'Role: Staff/Admin', children: [] });
    filter.push({ type: IFilterTypes.userType, key: `userType:Volunteers`, value: 'Volunteer', name: 'Role: Volunteer', children: [] });


    if (settings?.AdvancedUserRoles) {
      filter.push({ type: IFilterTypes.userType, key: `userType:AdvIsModerator`, value: 'AdvIsSpecialAwardJudge', name: 'Role: Proctor', children: [] });
      filter.push({ type: IFilterTypes.userType, key: `userType:AdvIsInterpreter`, value: 'AdvIsInterpreter', name: 'Role: Interpreter', children: [] });
      filter.push({ type: IFilterTypes.userType, key: `userType:AdvIsSpecialAwardJudge`, value: 'AdvIsSpecialAwardJudge', name: 'Role: Special Award Judge', children: [] });
    }

    if(settings?.TrackEntryStatus){
      let entries: IFitlerDisplay[] = [];
        entries.push({ type: IFilterTypes.entryType, key: `entryStatus:Pending`, value: `Pending`, name: props.Settings?.text.EntryStatusPending, children: [] });
        entries.push({ type: IFilterTypes.entryType, key: `entryStatus:Approved`, value: `Approved`, name: props.Settings?.text.EntryStatusApproved, children: [] });
        entries.push({ type: IFilterTypes.entryType, key: `entryStatus:Dropped`, value: `Dropped`, name: props.Settings?.text.EntryStatusDropped, children: [] });
        entries.push({ type: IFilterTypes.entryType, key: `entryStatus:DoNotAdvance`, value: `DoNotAdvance`, name: props.Settings?.text.EntryStatusDNA, children: [] });

      filter.push({ type: IFilterTypes.entryType, key: `Entry Status`, value: '', name: 'Entry Status', children: entries });
    }

    if (entryTypes && entryTypes.length > 0) {
      let entries: IFitlerDisplay[] = [];
      entryTypes.forEach((e) => {
        entries.push({ type: IFilterTypes.entryType, key: `entryType:${e.Id}`, value: `${e.Id}`, name: `${e.Name}`, children: [] });
      });

      filter.push({ type: IFilterTypes.entryType, key: `Entry Types`, value: '', name: 'Entry Types', children: entries });
    }

    
    if (fullResponse?.Divisions && fullResponse.Divisions.length > 0) {
      let divisions: IFitlerDisplay[] = [];
      fullResponse.Divisions.forEach((e) => {
        divisions.push({ type: IFilterTypes.entryType, key: `division:${e.Id}`, value: `${e.Id}`, name: `${e.Name}`, children: [] });
      });

      filter.push({ type: IFilterTypes.entryType, key: `Divisions`, value: '', name: 'Divisions', children: divisions });
    }

    if (fullResponse?.FlexFlows && fullResponse.FlexFlows.length > 0) {
      let divisions: IFitlerDisplay[] = [];
      fullResponse.FlexFlows.forEach((e) => {
        divisions.push({ type: IFilterTypes.entryType, key: `flexflow:${e.Id}`, value: `${e.Id}`, name: `${e.Name}`, children: [] });
      });

      filter.push({ type: IFilterTypes.entryType, key: `Flows`, value: '', name: 'Flex Flows', children: divisions });
    }
    
    if (fullResponse?.Grades && fullResponse.Grades.length > 0) {
      let grades: IFitlerDisplay[] = [];
      fullResponse.Grades.forEach((e) => {
        grades.push({ type: IFilterTypes.entryType, key: `grade:${e.Id}`, value: `${e.Id}`, name: `${e.Name}`, children: [] });
      });

      filter.push({ type: IFilterTypes.entryType, key: `Grade`, value: '', name: 'Grade', children: grades });
    }

    if(fullResponse?.InPersonAsked){
      let grades: IFitlerDisplay[] = [];
      grades.push({ type: IFilterTypes.entryType, key: `virtual:In`, value: `In Person`, name: `In Person`, children: [] });
      grades.push({ type: IFilterTypes.entryType, key: `virtual:Vir`, value: `Virtual`, name: `Virtual`, children: [] });

      filter.push({ type: IFilterTypes.entryType, key: `InPerson/Virtual`, value: '', name: 'Entry In Person / Virtual', children: grades });
    }


    if (fullResponse?.Categories?.length) {
      let cate: IFitlerDisplay = { name: 'Category', type: IFilterTypes.Category, key: `cate`, value: 'cate', children: [] };
      fullResponse.Categories.forEach((x) => {
        cate.children?.push({ name: x.Name, type: IFilterTypes.Category, key: `cate:${x.Id}`, value: `cate:${x.Id}`, children: [] });
      });

      filter.push(cate);
    }

    let tags: IFitlerDisplay[] = [];

    if (fullResponse?.PersonTags) {
      fullResponse.PersonTags.forEach((t, i) => {
        tags.push({ type: IFilterTypes.personTag, name: 'Person Tag: ' + t.Value, value: t.Id, key: `personTag:${t.Id}`, children: [] });
      });
      let personDict: Record<number, Tag> = fullResponse.PersonTags.reduce((pTagDict, pTag) => {
        return { ...pTagDict, [pTag.Id]: pTag };
      }, {});
      setPersonTagDict(personDict);
    }
    if (fullResponse?.ProjectTags) {
      fullResponse.ProjectTags.forEach((t, i) => {
        tags.push({ type: IFilterTypes.projectTag, name: 'Project Tag: ' + t.Value, value: t.Id, key: `projectTag:${t.Id}`, children: [] });
      });
      let projectDict: Record<number, Tag> = fullResponse.ProjectTags.reduce((pTagDict, pTag) => {
        return { ...pTagDict, [pTag.Id]: pTag };
      }, {});
      setProjectTagDict(projectDict);
    }
    if (fullResponse?.AutoTags) {
      fullResponse.AutoTags.forEach((t, i) => {
        tags.push({ type: IFilterTypes.autoTag, name: 'Auto Tag: ' + t.Value, value: t.Id, key: `autoTag:${t.Id}`, children: [] });
      });
      let autoDict: Record<number, Tag> = fullResponse.AutoTags.reduce((pTagDict, pTag) => {
        return { ...pTagDict, [pTag.Id]: pTag };
      }, {});
      setAutoTagDict(autoDict);
    }
    if (tags.length > 0) filter.push({ type: IFilterTypes.userType, key: `tags`, value: '', name: 'Tags', children: tags });
    setFilterOptions(filter);
  }, [fullResponse, settings]);

  useEffect(() => {
    filterBySearchStuff();
  }, [allPeople]);

  useEffect(() => {
    breakUpPeopleByPage();
  }, [filteredPeople]);

  useEffect(() => {
    filterBySearchStuff();
  }, [filterValue, selectedFilterOptions]);
  useEffect(() => {
    filterBySearchStuff();
  }, [filterGroup]);

  const breakUpPeopleByPage = () => {
    let indexsForLetters = filteredPeople.filter((person, i) => i % Constants.PAGE_SIZE === 0 || i % Constants.PAGE_SIZE === Constants.PAGE_SIZE - 1 || i === filteredPeople.length - 1);
    let lettersIWant = filteredPeople.filter((person, i) => indexsForLetters.includes(person)).map((person) => person.LastName.slice(0, 2));
    let pool = filteredPeople.length - Constants.PAGE_SIZE;
    let numberOfPages = pool / Constants.PAGE_SIZE; // + (pool % Constants.PAGE_SIZE === 0 ? 0 : 1);
    var pageLinks: PageLinkObj[] = [];
    for (let i = 0; i < numberOfPages + 1; i++) {
      pageLinks.push({ index: i, firstLetters: lettersIWant[i * 2], lastLetters: lettersIWant[i * 2 + 1] ?? '' });
    }
    setPageList(pageLinks);
    let chunkedPeople = _.chunk(filteredPeople, Constants.PAGE_SIZE);
    setFilteredPeopleByPage(chunkedPeople);
  };

  const updateTag = (person: GetPeoplePerson, ids, type, remove, userId, projectId) => {
    let people = [...filteredPeople];

    ids.forEach((tag) => {
      type = tag.type;
      let id = tag.id;
      if (type === 'person') {
        let p = people.find((x) => x.Id === person.Id);
        if (p) {
          if (!p.PersonTags) p.PersonTags = [];

          if (remove) {
            p.PersonTags = p.PersonTags.filter((x) => x !== id) ?? [];
          } else if (!p.PersonTags.find((x) => x === id)) {
            p.PersonTags.push(id);
          }
        }
        setFilteredPeople(people);
      } else {
        let p = people.find((x) => x.Id === person.Id && x.ProjectKey && x.ProjectKey === person.ProjectKey);
        if (p) {
          if (!p.ProjectTags) p.ProjectTags = [];

          if (remove) {
            p.ProjectTags = p.ProjectTags.filter((x) => x !== id) ?? [];
          } else if (!p.ProjectTags.find((x) => x === id)) {
            p.ProjectTags.push(id);
          }
        }
      }
    });

    setFilteredPeople(people);
  };

  const filterBySearchStuff = () => {
    if (pageActive !== 0) setPageActive(0);

    let showTypes: any = {};
    showTypes.All = true;
    let tags: any = {};
    let hasTags: boolean = false;
    let textSearch: string[] = [];
    let entryTypes: any = {};
    let hasEntryType = false;
    let hasCategory = false;
    let categoryHash = {};
    let paperworkReviewReady = false;
    let paperworkJudgingReady = false;
    let hasGrade = false;    
    let GradeHash = {};
    let hasDivision = false;
    let DivisionHash = {};
    let hasFlexFlow = false;
    let FlexFlowHash = {};
    let hasEntryStatus = false;
    let entryStatusHash = {};
    let isPendingEntryStatus = false;
    let IsInPerson = false;
    let IsVirtual = false;

    selectedFilterOptions?.forEach((x) => {
      let value = x.value + '';
      if (value.indexOf('Tag:') > 0) {
        tags[value.split(':')[1]] = true;
        hasTags = true;
      } else if (value === 'ready:review') {
        paperworkReviewReady = true;
      } else if (value === 'ready:judging') {
        paperworkJudgingReady = true;
      } else if (value.indexOf('cate:') > -1) {
        hasCategory = true;
        categoryHash[value.split(':')[1]] = true;
      } else if (value.indexOf('userType:') > -1) {
        showTypes.All = false;
        showTypes[value.split(':')[1]] = true;
      } else if (value.indexOf('virtual:In') > -1) {
        IsInPerson = true;
      } else if (value.indexOf('virtual:Vir') > -1) {
        IsVirtual = true;
      } else if (value.indexOf('flexflow:') > -1) {
        FlexFlowHash[value.split(':')[1]] = true;
        hasFlexFlow = true;
      }      else if (value.indexOf('entryStatus:Pending') > -1) {        
        isPendingEntryStatus = true;
      }    else if (value.indexOf('entryStatus:') > -1) {
        entryStatusHash[value.split(':')[1]] = true;
        hasEntryStatus = true;
      }  
      else if (value.indexOf('division:') > -1) {
        DivisionHash[value.split(':')[1]] = true;
        console.log(value);
        hasDivision = true;
      } else if (value.indexOf('grade:') > -1) {
        GradeHash[value.split(':')[1]] = true;
        console.log(value);
        hasGrade = true;
      }
      else {
        textSearch.push((x.value + '').toLowerCase());
      }
    });

    let filteredUsers = allPeople.filter((peep) => {
      if (hasEntryType) {
        if (!entryTypes[peep.EntryTypeId]) {
          return false;
        }
      }

      if (paperworkReviewReady && !peep.PaperworkReady) return false;
      if (paperworkJudgingReady && !peep.JudgingReady) return false;
      if (hasCategory && !categoryHash[peep.CategoryId + '']) return false;
      if (hasDivision && !DivisionHash[peep.Division +'']) return false;
      if (hasFlexFlow && !FlexFlowHash[peep.FlowId+'']) return false;
      if (hasGrade && !GradeHash[peep.Grade +'']) return false;
      if (isPendingEntryStatus && (!(!peep.EntryStatus || entryStatusHash[peep.EntryStatus]) || peep.Adult)) return false;
      if (hasEntryStatus && !entryStatusHash[peep.EntryStatus ??'']) return false;
      if (IsInPerson && (!peep.InPerson || peep.MaxRole !== 'Student')) return false;
      if (IsVirtual && (peep.InPerson || peep.MaxRole !== 'Student')) return false;

      let showTag = false;

      if (!hasTags) showTag = true;
      else showTag = peep.PersonTags?.some((p) => tags[p + '']) || peep.ProjectTags?.some((p) => tags[p + '']);
      //if (!showTag && projectTagsToLookFor.length > 0) showTag = peep.ProjectTags.some(p => projectTagsToLookFor.includes(p));

      if (!showTag) return false;

      let show = false;
      if (showTypes.All) show = true;
      if (!show && showTypes.Students && !peep.Adult && peep.MaxRole === 'Student' && !peep.TempPerson) show = true;
      if (!show && showTypes.Team && peep.Team) show = true;
      if (!show && showTypes.Adults && peep.Adult) show = true;
      if (!show && showTypes.Teachers && (peep.MaxRole === 'Teacher' || peep.IsTeacher)) show = true;
      if (!show && showTypes.Judges && peep.MaxRole === 'Judge') show = true;
      if (!show && showTypes.Volunteers && peep.MaxRole === 'Volunteer') show = true;

      if (!show && showTypes.AdvIsSpecialAwardJudge && peep.IsSpecialAwardJudge) show = true;
      if (!show && showTypes.AdvIsInterpreter && peep.IsInterpreter) show = true;
      if (!show && showTypes.AdvIsModerator && peep.IsModerator) show = true;

      if (!show && showTypes.TeamLeader && peep.TeamLeader) show = true;
      if (!show && showTypes.Temp && peep.TempPerson) show = true;
      if (!show && showTypes.Staff && (peep.MaxRole === 'Staff' || peep.MaxRole === 'Admin' || peep.MaxRole === 'Yoda')) show = true;

      if (!show) return false;

      let cachedFilterVal = filterValue.toLowerCase();

      for (let i = 0; i < textSearch.length; i++) {
        if (peep.Search.includes(textSearch[i])) return true;
      }

      if (textSearch.length > 0 && cachedFilterVal.length === 0) return false;

      return peep.Search.includes(cachedFilterVal);
    });

    if (filterGroup && filterGroup.Filters.length > 0) filteredUsers = searchPerson(filterGroup, filteredUsers);

    setFilteredPeople(filteredUsers);
  };

  const mappedFilteredPeopleByPage = filteredPeopleByPage?.[pageActive]?.map((p, i) => {
    if (settings)
      return (
        <PersonRow
          state={props}
          settings={settings}
          key={`${p.Id}-${i}-container`}
          person={p}
          projectTagDict={projectTagDict}
          personTagDict={personTagDict}
          autoTagDict={autoTagDict}
          setClickedIconModalOpen={setClickedIconModalOpen}
          setChosenPerson={setChosenPerson}
          showSelect={showSelect}
          selected={selected[p.Id] ?? false}
          setSelected={(checked: boolean) => {
            setSelected({ ...selected, [p.Id]: checked });
          }}
        />
      );
  });

  const handleIconModalClose = (bool: boolean) => {
    setChosenPerson(undefined);
    setClickedIconModalOpen(false);
  };

  const mappedPageLinks = pageList.map((link, i) => {
    return (
      <span
        key={`${link.firstLetters} - ${i}`}
        className={`page ${pageActive === link.index ? 'active' : ''}`}
        onClick={() => setPageActive(link.index)} //pMgr.BuildPage(${i})}
      >
        {link.lastLetters ? `${link.firstLetters} - ${link.lastLetters}` : link.firstLetters}
      </span>
    );
  });

  const filterUpdated = (filter: IFilterGroup): void => {
    setFilterGroup(filter);
  };

  return (
    <div>
      <div className="form-horizontal sticky people-page-search-bar" id="people-page-search-bar">
        <div className="flex-between">
          <div className="">
            <div className="flex wrap">
              <label className="control-label" htmlFor="search-it">
                Search{' '}
              </label>
              <div className="grow">
                <input className="form-control text-search" autoFocus={true} value={filterValue} onChange={(e) => setFilterValue(e.target.value?.replace(/\t/g,''))} />
              </div>
              <div className="grow">
                <Creatable
                  inputId={'search-it'}
                  onChange={setSelectedFilterOptions}
                  value={selectedFilterOptions}
                  options={filterOptions?.map((o) => {
                    if (o.children && o.children.length > 0) {
                      let children = o.children.map((x) => {
                        return { label: x.name, value: x.key };
                      });
                      return { label: o.name, options: children };
                    }
                    return { label: o.name, value: o.key };
                  })}
                  placeholder={'Search & Filter Options.'}
                  className="create-search"
                  isMulti
                  autoFucus={true}
                  isClearable={true}
                  menuPlacement={'auto'}
                />
              </div>
              <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  refreshAll(false);
                }}
                title="refresh data">
                <Icon type={IconType.refresh} />
              </button>
            </div>
          </div>
        </div>
        {fullResponse && <div className='flex-between'>
          <AdvancedFilter data={fullResponse} newFilter={filterUpdated} />
          <div>
          {! showSelect &&<button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  setShowSelect(!showSelect);
                }}
                title="refresh data">
                <Icon type={IconType.checklist} /> Select
              </button>}
          </div>
        </div>}

        <div className="flex-between wrap">
          <div className="people-stats-summary">
            <span>
              Showing {filteredPeople.length} of {allPeople.length}
            </span>
            {fullResponse && <span>{fullResponse.NumberOfProjects} Projects</span>}
            {fullResponse && (
              <span>
                {fullResponse.NumberOfStudents} {props.Settings?.text.ParticipantLabel}s
              </span>
            )}
            {fullResponse && <span>{fullResponse.Teams} Teams</span>}
          </div>

          {pageList && pageList.length > 1 && <div className="page-list"> {mappedPageLinks} </div>}

        </div>


        {fullResponse && showSelect && <div className='flex-between'>
          <div>
          <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  let records:Record<string,boolean> = {};
                  filteredPeople.forEach(x=>{
                    records[x.Id] = true;
                  });
                  setSelected(records);
                }}
                title="refresh data">
                <Icon type={IconType.checkSquareO} /> Select All
              </button>
          <button
                type="button"
                className="btn btn-default"
                onClick={() => {
                  setSelected({});
                }}
                title="refresh data">
                <Icon type={IconType.squareO} /> Deselect All
              </button>
            <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setShowBulkTag(true);
                }}
                title="refresh data">
                <Icon type={IconType.tags} /> Bulk Tag
              </button>
      

              </div>
            <div>
                
            </div>
              <div>
      <BulkEdits selected={selected} 
      refresh={()=>{
        refreshAll();
      }} 
      people={allPeople}
      schools={fullResponse.Schools}
      />
          <button
                type="button"
                className="btn btn-default btn-xs"
                onClick={() => {
                  setShowSelect(!showSelect);
                }}
                title="Hide select">
                <Icon type={IconType.checklist} /> Hide
              </button>
              </div>
        </div>}

      </div>



      <div id="people-container">
        <div className="table-responsive">
          <table className="table table-hover table-striped">
            <tbody>{mappedFilteredPeopleByPage}</tbody>
          </table>
        </div>
      </div>
      {pageList && pageList.length > 1 && <div className="page-list"> {mappedPageLinks} </div>}

      {clickedIconModalOpen && chosenPerson && settings && (
        <Modal setModalOpen={handleIconModalClose} title={`${chosenPerson.FirstName} ${chosenPerson.LastName}`}>
          <PersonContextMenu            
            settings={settings}
            person={chosenPerson}
            setChosenPerson={setChosenPerson}
            setModalOpen={handleIconModalClose}
            projectTags={fullResponse?.ProjectTags ?? []}
            personTags={fullResponse?.PersonTags ?? []}
            projectTagDict={projectTagDict}
            personTagDict={personTagDict}
            refreshAll={refreshAll}
            whoAmI={store.user}
            redirectUrl={redirectUrl}
            updateTag={updateTag}
            ShowEntriesPage={fullResponse?.ShowEntriesPage ?? false}
            fileTypes={fullResponse?.FileTypes ?? []}
          />
        </Modal>
      )}

      {showBulkTag && fullResponse && <TagModal 
          setModalOpen={setShowBulkTag} 
          projectTags={fullResponse.ProjectTags} personTags={fullResponse.PersonTags} 
          projectTagDict={projectTagDict} 
          personTagDict={personTagDict} 
          persons={allPeople.filter(x=>selected[x.Id])}
          setChosenPerson={()=>{}} 
          updateTag={(person: any, ids: any, type: any, remove: any)=> {
              updateTag(person, ids, type, remove, '', '');
          } } />
      }
    </div>
  );
};

export default withRouter(ReduxMap(PeoplePage));
