import { useEffect, useState } from 'react';
import redux, { IState } from '../../redux/redux';
import Icon, { IconType } from '../Icon/Icon';
import UserLogin from '../UserLogin/UserLogin';
import Button from '../_Core/Button';
import { IBranchTask } from '../../redux/types';
import Modal from '../Modal/Modal';
import BranchPortal from '../Setup/Branches/BranchPortal';

interface IProps {
  state: IState;
  type: string;
}

interface IWidgetData {}

const Widget = (props: IProps) => {
  const [data, setData] = useState<IWidgetData>();
  const [profileImageFailedToLoad, setProfileImageFailedToLoad] = useState<boolean>();
  const [selectedBranch, setSelectedBranch] = useState<IBranchTask>();
  const [showBranch, setShowBranch] = useState<boolean>(false);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    console.log('Widget Init Called...', props.type);
  };

  const buildWidget = () => {
    switch (props.type.toUpperCase()) {
      case 'ACCOUNT':
        return buildAccountWidget();
      case 'BRANCH':
        if (props.state.branchInfo) return buildBranchWidget(props.state.branchInfo);
        return <></>;
      case 'BRANCH-SUB':
        if (props.state.branchInfo?.SubBranch) return buildBranchWidget(props.state.branchInfo.SubBranch);
        return <></>;
      default:
        return (
          <div className="widget text-center">
            <h3>Unknown Widget "{props.type}"</h3>
          </div>
        );
    }
  };

  const buildAccountWidget = () => {
    if (props.state.User?.signedIn) {
      let user = props.state.User;
      console.log(user);
      return (
        <div className="widget widget-account">
          <div className="widget-header">
            <div className="widget-profile-img">
              {user.ProfileLink && !profileImageFailedToLoad ? (
                <>
                  <img
                    src={user.ProfileLink}
                    alt="Profile Image"
                    onError={(x) => {
                      setProfileImageFailedToLoad(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <Icon type={IconType.userProfileImage} />
                </>
              )}
            </div>
            <div className='widget-user-info'>
              <h4>
                {user.firstName} {user.lastName}
              </h4>
              {user.email}
            </div>
          </div>
        </div>
      );
    } else {
      //Create Account / Sign in
      return (
        <div className="widget">
          <h3>
            <Icon type={IconType.user} addClass="text-muted" /> Account Info
          </h3>
          <Button
            type="login"
            addClass="btn-block"
            text="Login"
            onClick={() => {
              redux.showLogin(true);
            }}
          />
          <Button type="register" addClass="btn-block" text="Register" />
        </div>
      );
    }
  };

  const buildBranchWidget = (info: IBranchTask) => {
    return (
      <div
        className={`widget branch-entry-bar ${info.ClassName ?? ''} ${info.CallToActionText ? '':'click'}`}
        onClick={() => {
          if(!info.CallToActionText) {
            setSelectedBranch(info);
            setShowBranch(true);
          }
        }}>
        <div className="entry-bar-text">
          <div dangerouslySetInnerHTML={{ __html: info.Title }}></div>
        </div>
        <br/>
        {/* <div>{info.EntryBarText ?? 'Click Here'}</div> */}
        
        <div dangerouslySetInnerHTML={{__html:info.EntryBarText ?? 'Click Here'}} />
        {info.CallToActionText && <div>
          <br/>
            <button 
            className='btn btn-info'
            onClick={()=>{

              setSelectedBranch(info);
              setShowBranch(true);

            }}  dangerouslySetInnerHTML={{__html:info.CallToActionText}}>

            </button>
          </div>}
      </div>
    );
  };

  return (
    <>
      <div className="widget-container">{buildWidget()}</div>
      {showBranch && selectedBranch && (
            <Modal setModalOpen={setShowBranch} title={selectedBranch.Title} size="xl">
              {/* <SetupWizard wizardKey={data.ContentKey ?? ''} /> */}
              <BranchPortal type={selectedBranch.Type} />
            </Modal>
          )}
    </>
  );
};

export default Widget;
