import React, { useState, useEffect } from 'react';
import { IAward, ITag } from '../../redux/judgingResults/types';
import Icon, { IconType } from '../Icon/Icon';
// import Store, { store } from "../../redux/oldStore";
import imgClouds from '../../../public/Assets/Images/clouds.jpg';
import imgMissingPerson from '../../../public/Assets/Images/unknownUser.png';
import { SlickImage, SlickImageGroup } from '../Tools/ExpandableDiv';
import { IShowcaseSettings, IProject } from './ShowcaseTypes';
import StoreX, { store } from '../../redux/oldStore';
import { imageExtTypeDic } from './projectDetails2';

interface ProjectProps {
  project: IProject;
  openProject: Function;
  settings: IShowcaseSettings;
  tags: ITag[];
  categoryAwardsDict: { [key: number]: IAward };
  specialAwardsDict: { [key: number]: IAward };
  likes: number;
  myLikes: any;
  likeProject: Function;
}

export default function Project(props: ProjectProps) {
  const { project, settings } = props;
  let owner = project.owners[0];
  const [thisTags, setThisTags] = useState<ITag[]>([]);
  const [thisSpecialAwards, setThisSpecialAwards] = useState<IAward[]>([]);
  const [thisCategoryAwards, setThisCategoryAwards] = useState<IAward[]>([]);
  const [likes, setLikes] = useState<number>(props.likes);
  //if (!owner.profileKey) return null;

  useEffect(() => {
    if (project.myTags) {
      let theseTags = props.tags.filter((tag) => project.myTags.includes(tag.Id));
      setThisTags(theseTags);
    }
  }, [project, props.tags]);

  useEffect(() => {
    if (project.categoryAwards) {
      let newArr = project.categoryAwards?.filter((x) => props.categoryAwardsDict[x]).map((num) => props.categoryAwardsDict[num]);
      setThisCategoryAwards(newArr);
    }
  }, [project, props.categoryAwardsDict]);

  useEffect(() => {
    if (project.specialAwards) {
      let newArr = project.specialAwards?.filter((x) => props.specialAwardsDict[x]).map((num) => props.specialAwardsDict[num]);
      setThisSpecialAwards(newArr);
    }
  }, [project, props.specialAwardsDict]);

  const handleLike = (e) => {
    const value = props.myLikes[project.projectIntId];
    var like = true;
    if (value === true || value === 'true') like = false;
    props.myLikes[project.projectIntId] = like;

    if (like) setLikes(likes + 1);
    else setLikes(likes - 1);

    props.likeProject(project.projectIntId, like);
    e.stopPropagation();
  };

  const getScaleCssClass = () => {
    const x = project.owners.filter((x) => x.profileKey).length;
    if (x === 1) return '';
    if (x < 5) return 'img-50';
    if (x < 10) return 'img-33';
    return '';
  };

  const getImage = () => {
    let image = project.images?.filter((x) => imageExtTypeDic[x.ext])[0];

    if (settings.slidesAsTileImage) {
      let slides = project.images.find((x) => x.fileType == 'slides-upload');
      if (slides) {
        let url = `${StoreX.BuildUrl(`../File/${slides.key}/true/500`)}`;
        image = { path: url, comments: 'slides' };
        return <SlickImage image={image} />;
      }
    }

    if (settings.profileImagesInTiles) {
      let images: any[] = [];
      project.owners.forEach((x) => {
        let url = '';
        if (x.profileKey) {
          url = `${x.profilePath}`;
        } else {
          url = imgMissingPerson;
        }
        images.push({ path: url, comments: `${x.firstName} ${x.lastName}` });
      });

      if (images.length > 0 || true) {
        return <SlickImageGroup images={images} onErrorImage={imgMissingPerson} />;
      }
    }

    return <SlickImage image={image} />;
  };
  const getImage2 = () => {
    let image = project.images?.filter((x) => imageExtTypeDic[x.ext])[0];

    if (settings.slidesAsTileImage) {
      let slides = project.images.find((x) => x.fileType == 'slides-upload');
      if (slides) {
        let url = `${StoreX.BuildUrl(`../File/${slides.key}/true/500`)}`;
        image = { path: url, comments: 'slides' };
        return <SlickImage image={image} />;
      }
    }

    if (settings.profileImagesInTiles) {
      let images: any[] = [];
      project.owners.forEach((x) => {
        let url = '';
        let found = false;
        if (x.profileKey) {
          url = `${x.profilePath}`;
          found = true;
        } else {
          url = imgMissingPerson;
        }
        if (found) images.push({ path: url, comments: `${x.firstName} ${x.lastName}` });
      });
      if (images.length === 1) {
        return <SlickImage image={images[0]} />;
      } else if (images.length > 0) {
        return <SlickImageGroup images={images} onErrorImage={imgMissingPerson} />;
      }
    }

    if(image) return <SlickImage image={image}/>

    return <h2>{project.title}</h2>;
  };

  return (
    <>
      <div
        className="showcase-project-3"
        onClick={(e) => {
          props.openProject(project);
        }}>
        <div className="showcase-tile-header">{getImage2()}


        <div className="sub-footer"><div>
            {props.settings.showAwards && (
              <>
                {project.categoryAwards
                  ?.filter((x) => props.categoryAwardsDict[x])
                  .map((num, i: number) => {
                    return (
                      <span className="text-danger" key={`${props.categoryAwardsDict[num].Id} - ${i}`} title={props.categoryAwardsDict[num].Name}>
                        <i className="fas fa-award"></i>
                      </span>
                    );
                  })}
                {project.specialAwards
                  ?.filter((x) => props.specialAwardsDict[x])
                  .map((num, i: number) => {
                    return (
                      <span className="text-info" key={`${props.specialAwardsDict[num].Id} - ${i}`} title={props.specialAwardsDict[num].Name}>
                        <i className="fas fa-award"></i>
                      </span>
                    );
                  })}
              </>
            )}
            {thisTags.map((tag, i) => {
              return (
                <span key={`${tag.Id}-${i}`} className="showcase-tag text-warning" title={tag.Value}>
                  <Icon type={IconType.tag} />
                </span>
              );
            })}
            </div>
          <div>
            {settings.recordLikes && (
              <div className="like-container" onClick={handleLike}>
                {likes > 0 && <>{likes}</>}
                {props.myLikes[project.projectIntId] ? <i className="fad fa-thumbs-up text-info"></i> : <i className="fal fa-thumbs-up text-muted"></i>}
              </div>
            )}
          </div>
          </div>
        </div>
        <div className="showcase-tile-body">
          <div className="title">{project.title}</div>
          <div className="between">
            <div>{project.category && <span>{project.category}</span>}</div>
            <div>
              {project.projectId && (
                <>
                  {project.projectId}
                  <br />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="showcase-tile-footer">
          {!settings.hideShowcaseStudentName && (
            <div className="owners">
              {project.owners.map((x: any, i: number) => {
                return (
                  <span key={`owner-${i}`}>
                    {x.firstName} {x.lastName}
                  </span>
                );
              })}
            </div>
          )}

          
        </div>
        <div></div>
      </div>
      {false && (
        <div
          className="showcase-project-2"
          onClick={(e) => {
            props.openProject(project);
          }}>
          <div className="header">
            <div className="no-wrap">{project.projectId}</div>
            <div className="ellipsis">{project.category}</div>
          </div>

          <div className="body">
            {getImage()}

            <div>
              <div className={`title`}>{project.title}</div>

              <div>
                {!settings.hideShowcaseStudentName && (
                  <div className="owners">
                    {project.owners.map((x: any, i: number) => {
                      return (
                        <span key={`owner-${i}`}>
                          {x.firstName} {x.lastName}
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="footer">
            <div>
              {props.settings.showAwards && (
                <>
                  {project.categoryAwards
                    ?.filter((x) => props.categoryAwardsDict[x])
                    .map((num, i: number) => {
                      return (
                        <span className="text-danger" key={`${props.categoryAwardsDict[num].Id} - ${i}`} title={props.categoryAwardsDict[num].Name}>
                          <i className="fas fa-award"></i>
                        </span>
                      );
                    })}
                  {project.specialAwards
                    ?.filter((x) => props.specialAwardsDict[x])
                    .map((num, i: number) => {
                      return (
                        <span className="text-info" key={`${props.specialAwardsDict[num].Id} - ${i}`} title={props.specialAwardsDict[num].Name}>
                          <i className="fas fa-award"></i>
                        </span>
                      );
                    })}
                </>
              )}
              {thisTags.map((tag, i) => {
                return (
                  <span key={`${tag.Id}-${i}`} className="showcase-tag text-warning" title={tag.Value}>
                    <Icon type={IconType.tag} />
                  </span>
                );
              })}
            </div>
            <div>
              {settings.recordLikes && (
                <div className="like-container" onClick={handleLike}>
                  {likes > 0 && <>{likes}</>}
                  {props.myLikes[project.projectIntId] ? <i className="fad fa-thumbs-up text-info"></i> : <i className="fal fa-thumbs-up text-muted"></i>}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
